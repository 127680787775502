.switchContainer {
    display: flex;
    align-items: center;

    label {
        margin-left: 0.5rem;
    }
}

.switch {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 28px;
    border-radius: 14px;
    background-color: #9e9e9e;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    .track {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #fafafa;
        transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);

        .thumb {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #f5f5f5;
            transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
    }

    &--checked {
        background-color: #4caf50;

        .track {
            transform: translateX(20px);

            .thumb {
                left: calc(100% - 22px);
                background-color: #fff;
            }
        }
    }

    input[type="checkbox"] {
        display: none;
    }
}
