.progressContainer {
    width: 100%;
    background-color: rgb(192, 186, 244);
    overflow: hidden;
}

.progressBar {
    height: 10px;
    background-color: var(--primary-color);
    width: 0%;
    transition: width 0.3s ease-in-out;
}