.row {
    &.tableRow {
        border: 0 !important;
        background-color: var(--surface-color);

        &:hover {
            background-color: var(--secondary-color) !important;
        }

        &:nth-of-type(odd) {
            background-color: var(--background-color);
        }

        // Hide last border
        &:last-child td,
        &:last-child th {
            border: 0 !important;
        }
    }
}