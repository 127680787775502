.toc-container {
  &-inner {
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow: auto;
    margin-top: 1rem;
    padding-right: 0.5rem;

    .toc-item-wrappe {
      cursor: pointer;

      &:hover {
        background: var(--secondary-color);
      }
    }

    &-item {
    }
  }
}
